<template>
  <div>
    <CCard>
      <CCardHeader>
        Admin Users
        <CButton square class='float-right' color='info' size='sm' variant='outline' to='/admins/create'>
          Add New User
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CDataTable hover :items='items' :fields='fields' :columnFilter='{ external: true, lazy: true }' :columnFilterValue.sync='currentFilters' :sorter='{ external: true }' :sorterValue='currentSort' @update:sorter-value='changeSort' @update:column-filter-value='changeFilter' :loading='isLoading' :responsive='true'>
          
          <template #status='{item}'>
            <td>
              <CBadge v-if='item.status == 0' color='success'>Active</CBadge>
              <CBadge v-if='item.status == 1' color='warning'>Inactive</CBadge>
            </td>
          </template>

          <template #status-filter='{item}'>
            <CSelect class='table-select' size='sm' v-model='currentFilters.status' :options="[ { value: null, label: 'All' }, { value: 0, label: 'Active' }, { value: 1, label: 'Inactive' } ]" @change='changeStatusFilter($event)'/>  
          </template>
          
          <template #createdAt-filter='{item}'>
            <div></div>
          </template>
          
          <template #lastLoggedInAt-filter='{item}'>
            <div></div>
          </template>

          <template #operations='{item, index}'>
            <td class='py-2'>
              <CButtonGroup>
                <CButton square color='info' size='sm' variant='outline' @click="$router.push({name: 'Edit Admin', params: { id: item._id }})">
                  <CIcon name='cil-pencil'/>
                </CButton>
                <CButton square color='success' size='sm' variant='outline' v-if='item.status == 1' @click="opModalShow('unsuspend', item)">
                  <CIcon name='cil-lock-locked'/>
                </CButton>
                <CButton square color='warning' size='sm' variant='outline' v-if='item.status == 0' @click="opModalShow('suspend', item)">
                  <CIcon name='cil-lock-locked'/>
                </CButton>
                <CButton square color='danger' size='sm' variant='outline' @click="opModalShow('delete', item)">
                  <CIcon name='cil-trash'/>
                </CButton>
              </CButtonGroup>
            </td>
          </template>

          <template v-slot:loading>
            <CElementCover :boundaries="[{ sides: ['top'], query: 'td' }, { sides: ['bottom'], query: 'tbody' }]">
              <CSpinner color='secondary'/>
            </CElementCover>
          </template>

          <template v-slot:no-items-view>
            <div class='text-center my-5'>
              No data
            </div>
          </template>

        </CDataTable>
        <CPagination align='center' :activePage.sync='currentPage' :pages='pages' @update:activePage='changePage'/>
      </CCardBody>

    </CCard>

    <CModal :title='opModalTitle' :color='opModalColor' :centered='true' :show.sync='opModal' @update:show='opModalConfirm'>
      {{ opModalText }}
    </CModal>

  </div>
</template>

<script>
import apiAdmin from '@/api/admin'

const items = [
]

const fields = [
  { key: 'username', _style:'min-width:80px' },
  { key: 'status', _style:'min-width:50px;' },
  { key: 'createdAt', label: 'Created', _style:'min-width:50px;' },
  { key: 'lastLoggedInAt', label: 'Last Login', _style:'min-width:50px;' },
  { key: 'operations', label: '', _style: 'width:1%', sorter: false, filter: false }
]

export default {
  name: 'ListAdmin',
  components: {
  },
  data () {
    return {
      isLoading: false,
      items,
      fields,
      pages: 0,
      currentPage: 1,
      currentSortColumn: null,
      currentSortOrder: null,
      currentFilters: {},
      currentSort: {},
      opModal: false,
      opModalOperation: '',
      opModalTitle: '',
      opModalText: '',
      opModalColor: '',
      opModalItem: null
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newVal) {
        if(Object.keys(newVal).length == 0){
          this.currentPage = 1
          this.currentSortColumn = null
          this.currentSortOrder = null
          this.currentFilters = {}
        }else{
          if(newVal.page) { this.currentPage = parseInt(newVal.page) }
          if(newVal.sortColumn) { this.currentSortColumn = newVal.sortColumn }
          if(newVal.sortOrder) { this.currentSortOrder = parseInt(newVal.sortOrder) }
          if(newVal.filters) { this.currentFilters = JSON.parse(decodeURI(newVal.filters)) }
          if(newVal.sortColumn && newVal.sortOrder){
            this.currentSort = {column: newVal.sortColumn, asc: (newVal.sortOrder == 1) ? true : false }
          }
        }
        this.fetchList(this.currentPage, 10, this.currentSortColumn, this.currentSortOrder, this.currentFilters)
      }
    }
  },
  created() {
  },
  methods: {
    
    /* Table List Functions */
    
    fetchList(page, limit, sortColumn, sortOrder, filters) {
      this.isLoading = true
      apiAdmin.list(page, limit, sortColumn, sortOrder, filters).then(response => {
        this.items = response.data.data
        this.pages = Math.ceil(response.data.count / 10)
        this.isLoading = false
      }).catch((error) => {
        this.$toast.error('Error loading list.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
      })
    },
    changePage(val) {
      this.currentPage = val
      this.$router.push({ name: 'List Admin', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)) } })
    },
    changeSort(val) {
      this.currentSortColumn = val.column
      this.currentSortOrder = (val.asc) ? 1 : -1;
      this.$router.push({ name: 'List Admin', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)) } })
    },
    changeStatusFilter(event) {
      this.currentFilters['status'] = parseInt(event.target.value)
      if(!event.target.value){
       delete this.currentFilters['status'] 
      }
      this.$router.push({ name: 'List Admin', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)) } })
    },
    changeFilter(val) {
      if(val.username == ''){
        delete this.currentFilters['username'] 
      }else{
        this.currentFilters['username'] = val.username
      }
      this.$router.push({ name: 'List Admin', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)) } })
    },
    

    /* List Item Operations */

    opModalShow(operation, item) {
      this.opModal = true
      this.opModalOperation = operation
      this.opModalItem = item
      if(operation == 'unsuspend' || operation == 'suspend'){
        if(operation == 'unsuspend'){
          this.opModalTitle = 'Unsuspend Admin User ' + item.username
          this.opModalText = 'Are you sure you wish to unsuspend this admin user "' + item.username + '" ?'
          this.opModalColor = 'success'
        }else{
          this.opModalTitle = 'Suspend Admin User ' + item.username
          this.opModalText = 'Are you sure you wish to suspend this admin user "' + item.username + '" ?'
          this.opModalColor = 'warning'
        }
      }
      if(operation == 'delete'){
        this.opModalTitle = 'Delete Admin User ' + item.username
        this.opModalText = 'Are you sure you wish to delete this admin user "' + item.username + '" ?'
        this.opModalColor = 'danger'
      }
    },
    opModalConfirm(show, event, confirm) {
      if(confirm){
        this.opModal = false
        this.isLoading = true
        if(this.opModalOperation == 'unsuspend' || this.opModalOperation == 'suspend'){
          var status = (this.opModalOperation == 'suspend') ? 1 : 0
          apiAdmin.update(this.opModalItem._id, '', status).then(response => {
            this.clearOpModalProps()
            this.$router.replace({ name: 'List Admin', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), status: require('crypto').randomBytes(24).toString('hex') } })
            var successtext = (status == 1) ? 'suspended' : 'activated'
            this.$toast.success('Successfully ' + successtext + '.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
          }).catch((error) => {
            var errortext = (status == 1) ? 'suspending' : 'activating'
            this.$toast.error('Error ' + errortext + '.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
            this.clearOpModalProps()
          })
        }
        if(this.opModalOperation == 'delete'){
          apiAdmin.delete(this.opModalItem._id).then(response => {
            this.clearOpModalProps()
            this.$router.replace({ name: 'List Admin', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), deleted: require('crypto').randomBytes(24).toString('hex') } })
            this.$toast.success('Successfully deleted.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
          }).catch((error) => {
            this.$toast.error('Error deleting.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
            this.clearOpModalProps()
          })
        }
      }else{
        this.clearOpModalProps()
      }
    },
    clearOpModalProps(){
      this.opModal = false
      this.opModalOperation = ''
      this.opModalTitle = ''
      this.opModalText = ''
      this.opModalColor = ''
      this.opModalItem = null
      this.isLoading = false
    }
  }
}
</script>
